<template>
  <section class="start">
    <div class="container">
      <h2 class="section-title">{{
          $vuetify.lang.t("$vuetify.view.forPsychologists.start.title")
        }}</h2>
      <ul class="start__list d-flex justify-center flex-wrap">
        <li class="start__item" v-for="start in startList" :key="start.id">
          <div class="d-flex align-center">
            <div class="start__number-wrapper">
              <div class="start__number">{{ start.id }}</div>
            </div>
            <h4 class="start__item-title list-title">{{ start.title }}</h4>
          </div>
          <p class="start__item-description" lang="ru">{{ start.description }}</p>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: 'StartSection',
  computed: {
    startList () {
      return [
        {
          id: 1,
          title: this.$vuetify.lang.t('$vuetify.view.forPsychologists.start.listTitle[0]'),
          description: this.$vuetify.lang.t('$vuetify.view.forPsychologists.start.listDescription[0]')
        },
        {
          id: 2,
          title: this.$vuetify.lang.t('$vuetify.view.forPsychologists.start.listTitle[1]'),
          description: this.$vuetify.lang.t('$vuetify.view.forPsychologists.start.listDescription[1]')
        },
        {
          id: 3,
          title: this.$vuetify.lang.t('$vuetify.view.forPsychologists.start.listTitle[2]'),
          description: this.$vuetify.lang.t('$vuetify.view.forPsychologists.start.listDescription[2]')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.list-title {
  color: $lightColor;
  font-size: 1.3rem;
  font-weight: 400;
};
.start {
  background-color: $backgroundColor;
  padding: 70px 0 120px;
  &__list {
    margin-top: 60px;
    list-style: none;
    gap: 70px;
  };
  &__item {
    flex-basis: 320px;
  }
  &__number-wrapper {
    background-color: $lightColor;
    transform: rotateZ(45deg);
  };
  &__number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    font-size: 2rem;
    transform: rotateZ(-45deg);
    color: #ffffff;
  };
  &__item-title {
    display: flex;
    align-items: center;
    margin-left: 35px;
    min-height: 60px;
    margin-right: 35px;
  };
  &__item-description {
    margin-top: 20px;
    color: $darkTextColor;
    hyphens: auto;
  };
};
</style>
