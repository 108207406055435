<template>
  <div>
    <preview-section></preview-section>
    <requirements-section></requirements-section>
    <advantages-section></advantages-section>
    <start-section></start-section>
    <faq-section></faq-section>
  </div>
</template>

<script>
import PreviewSection from '@/components/main/ForPsychologist/PreviewSection'
import RequirementsSection from '@/components/main/ForPsychologist/RequirementsSection'
import AdvantagesSection from '@/components/main/ForPsychologist/AdvantagesSection'
import StartSection from '@/components/main/ForPsychologist/StartSection'
import FaqSection from '@/components/main/ForPsychologist/FaqSection'
export default {
  name: 'ForPsychologist',
  components: {
    PreviewSection,
    RequirementsSection,
    AdvantagesSection,
    StartSection,
    FaqSection
  },
  metaInfo () {
    return {
      title: 'Робота для психологів онлайн┃Платформа для заробітку \n' +
        'психологам',
      meta: [
        {
          vmid: 'og:description',
          property: 'og:description',
          name: 'description',
          content: 'Вам не потрібно займатися своїм просуванням. Ми приводимо \n' +
            'клієнтів готових до роботи. Зручний робочий кабінет. Швидкі \n' +
            'виплати на карту'
        },
        {
          name: 'keywords',
          content: 'психолог онлайн, психолог онлайн україна, психолог робота, \n' +
            'психолог вакансії, психотерапевт, сексолог'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.v-icon.notranslate.fas.fa-chevron-down.theme--light {
  display: none !important;
}
.v-expansion-panel--active > .v-expansion-panel-header--active .v-expansion-panel-header__icon:not(.v-expansion-panel-header__icon--disable-rotate) .v-icon {
  transform: rotate(-135deg)!important;
}
.theme--light.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon{
  color: #00A4B7 !important;
}
</style>
