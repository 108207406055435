<template>
  <section class="requirements">
    <div class="container">
      <h2 class="section-title">{{
          $vuetify.lang.t("$vuetify.view.forPsychologists.requirements.title")
        }}</h2>
      <h3 class="section-subtitle d-block">{{
          $vuetify.lang.t("$vuetify.view.forPsychologists.requirements.subtitle")
        }}</h3>
      <ul class="requirements__list d-flex flex-wrap justify-center">
        <li class="requirements__item" v-for="item in requirementsList" :key="item.id" >
          <div class="requirements__item-picture">
            <img :src="item.src" alt="education" class="requirements__item-img">
          </div>
          <h4 class="list-title">{{ item.title }}</h4>
          <p class="requirements__item-description" lang="ru">{{ item.description }}</p>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: 'RequirementsSection',
  computed: {
    requirementsList () {
      return [
        {
          id: 1,
          title: this.$vuetify.lang.t('$vuetify.view.forPsychologists.requirements.listTitle[0]'),
          description: this.$vuetify.lang.t('$vuetify.view.forPsychologists.requirements.listDescription[0]'),
          src: require('@/assets/images/forPsychologists/require-1.png')
        },
        {
          id: 2,
          title: this.$vuetify.lang.t('$vuetify.view.forPsychologists.requirements.listTitle[1]'),
          description: this.$vuetify.lang.t('$vuetify.view.forPsychologists.requirements.listDescription[1]'),
          src: require('@/assets/images/forPsychologists/require-2.png')
        },
        {
          id: 3,
          title: this.$vuetify.lang.t('$vuetify.view.forPsychologists.requirements.listTitle[2]'),
          description: this.$vuetify.lang.t('$vuetify.view.forPsychologists.requirements.listDescription[2]'),
          src: require('@/assets/images/forPsychologists/require-3.png')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.list-title {
  color: $lightColor;
  font-size: 1.3rem;
  font-weight: 400;
};
.requirements {
  background-color: $backgroundColor;
  padding: 50px 0 100px;
&__list {
   list-style: none;
   margin-top: 50px;
   gap: 25px;
 };
&__item {
   flex-basis: 350px;
   flex-shrink: 1;
   background-color: white;
   border-radius: 10px;
   padding: 0 20px;
   overflow: hidden;
 };
&__item-picture {
   max-width: 150px;
   height: 200px;
   margin: 0 auto;
 };
&__item-img {
   max-width: 100%;
   height: 100%;
 };
&__item-description {
   color: $darkTextColor;
   font-size: 1rem;
   text-align: justify;
   hyphens: auto;
   margin-top: 10px;
 };
}
</style>
