<template>
  <section class="advantages">
    <div class="container">
      <h2 class="section-title">{{
          $vuetify.lang.t("$vuetify.view.forPsychologists.advantages.title")
        }}</h2>
      <ul class="advantages__list d-flex justify-center flex-wrap">
        <li class="advantages__item" v-for="adv in advantagesList" :key="adv.id">
          <h4 class="list-title">{{ adv.title }}</h4>
          <p class="advantages__item-description">{{ adv.description }}</p>
        </li>
      </ul>
      <div class="d-flex align-center flex-column">
        <a class="main-btn advantages__btn" :href="linkToDashboard" target="_blank">{{
            $vuetify.lang.t("$vuetify.view.forPsychologists.advantages.button")
          }}</a>
        <p class="decor-text advantages__decor-text">{{
            $vuetify.lang.t("$vuetify.view.forPsychologists.advantages.decorText")
          }}</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AdvantagesSection',
  data () {
    return {
      linkToDashboard: process.env.VUE_APP_DASHBOARD + '/psychologistRegForm'
    }
  },
  computed: {
    advantagesList () {
      return [
        {
          id: 1,
          title: this.$vuetify.lang.t('$vuetify.view.forPsychologists.advantages.listTitle[0]'),
          description: this.$vuetify.lang.t('$vuetify.view.forPsychologists.advantages.listDescription[0]')
        },
        {
          id: 2,
          title: this.$vuetify.lang.t('$vuetify.view.forPsychologists.advantages.listTitle[1]'),
          description: this.$vuetify.lang.t('$vuetify.view.forPsychologists.advantages.listDescription[1]')
        },
        {
          id: 3,
          title: this.$vuetify.lang.t('$vuetify.view.forPsychologists.advantages.listTitle[2]'),
          description: this.$vuetify.lang.t('$vuetify.view.forPsychologists.advantages.listDescription[2]')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.list-title {
  color: $lightColor;
  font-size: 1.3rem;
  font-weight: 400;
};
.advantages {
  padding: 50px 0 60px;
&__list {
   margin-top: 60px;
   list-style: none;
   gap: 70px;
 };
&__item {
   flex-basis: 320px;
 };
&__item-description {
   color: $darkTextColor;
   text-align: justify;
   hyphens: auto;
   margin-top: 10px;
   font-size: 1.1rem;
 };
&__btn {
   position: relative;
   max-width: 350px;
   margin-top: 90px;
   width: 100%;
   text-decoration: none;
   text-align: center;
   color: white !important;
&::after {
   position: absolute;
   content: "";
   background-image: url("../../../assets/images/forPsychologists/arrow-2.png");
   width: 130px;
   height: 130px;
   top: 40%;
   left: 120%;
   background-size: contain;
 }
}
&__decor-text {
   margin-top: 40px;
   font-size: 1.8rem;
   font-weight: 500;
 }
}
@media screen and (max-width: 768px) {
  .advantages__btn::after {
    content: none;
  }
}
</style>
