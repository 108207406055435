<template>
  <section class="preview">
    <div class="container d-flex justify-space-between">
      <div class="preview__content d-flex flex-column align-center">
        <h1 class="preview__title">{{
            $vuetify.lang.t("$vuetify.view.forPsychologists.mainTitle")
          }}</h1>
        <h2 class="preview__subtitle">{{
            $vuetify.lang.t("$vuetify.view.forPsychologists.subtitle")
          }}</h2>
        <a class="main-btn preview__btn" :href="linkToDashboard" target="_blank">{{
            $vuetify.lang.t("$vuetify.view.forPsychologists.registrationButton1")
          }}</a>
        <p class="decor-text preview__decor-text">{{
            $vuetify.lang.t("$vuetify.view.forPsychologists.decorText")
          }}</p>
      </div>
      <div class="preview__picture">
        <img src="@/assets/images/forPsychologists/Main-image.png" alt="talks" class="preview__img">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PreviewSection',
  data () {
    return {
      linkToDashboard: process.env.VUE_APP_DASHBOARD + '/psychologistRegForm'
    }
  }
}
</script>

<style lang="scss" scoped>
.preview {
  margin-top: 65px;
  padding-bottom: 50px;
  &____content {
  }
  &__title {
    width: 100%;
    max-width: 410px;
    margin: 0 auto;
    text-align: center;
    font-weight: 400;
    line-height: normal;
    padding-top: 40px;
    font-size: 2rem;
    font-weight: 500;
    color: $lightColor;
  };
  &__subtitle {
    max-width: 440px;
    margin-top: 30px;
    text-align: center;
    color: $darkTextColor;
    font-size: 1.3rem;
    font-weight: 400;
  };
  &__btn {
    position: relative;
    max-width: 320px;
    margin-top: 70px;
    width: 100%;
    text-decoration: none;
    text-align: center;
    color: white;
    &::after {
      position: absolute;
      content: "";
      background-image: url("../../../assets/images/forPsychologists/arrow.png");
      width: 100px;
      height: 100px;
      top: 50%;
      left: 100%;
      background-size: contain;
    }
  };
  &__decor-text {
    color: $lightColor;
    font-size: 1.8rem;
    font-weight: 500;
    margin-top: 40px;
  };
  &__arrow {
    position: absolute;
    height: 60%;
    top: 45px;
    right: -20px;
  }
  &__picture {
    margin-left: 20px;
    max-width: 623px;
  };
  &__img {
    width: 100%;
    object-fit: contain;
    height: 100%;
  }
}
@media screen and (max-width: 768px) {
  .container {
    flex-wrap: wrap;
    justify-content: center !important;
  }
  .preview {
    &__btn::after {
      content: none;
    }
  }
}
</style>
